function runCookieConsent() {
  var cookieconsent = initCookieConsent();

  cookieconsent.run({
    autorun: true,
    page_scripts: true,
    delay: 0,
    current_lang: document.documentElement.getAttribute('lang'),
    auto_language: false,
    theme_css: 'https://agencegroom.com/css/cookieconsent.css',
    autoclear_cookies: true,
    cookie_expiration: 365,
    force_consent: false,

    onAccept: function (cookies) {
      // do something ...
      console.log('onAccept fired ...');
    },

    languages: {
      fr: {
        consent_modal: {
          title: 'Miam... des cookies ! 🍪',
          description:
            'Nous nous soucions de vos données, et nous aimerions utiliser des cookies pour améliorer votre expérience. Nous ne ferons rien sans votre consentement. <button type="button" data-cc="c-settings" class="cc-link" aria-haspopup="dialog">Laissez-moi choisir</button>',
          primary_btn: {
            text: 'Ok',
            role: 'accept_all', //'accept_selected' or 'accept_all'
          },
          secondary_btn: {
            text: 'Non, merci !',
            role: 'accept_necessary', //'settings' or 'accept_necessary'
          },
        },
        settings_modal: {
          title: 'Agence Groom - Gestion des cookies',
          save_settings_btn: 'Sauvegarder',
          accept_all_btn: 'Tout accepter',
          cookie_table_headers: [
            {col1: 'Nom'},
            {col2: 'Domaine'},
            {col3: 'Expiration'},
            {col4: 'Description'},
            {col5: 'Type'},
          ],
          blocks: [
            {
              title: 'Utilisation des cookies',
              description:
                'Nous utilisons des cookies pour améliorer votre expérience sur le site de notre agence, pour mesurer notre audience et aussi des cookies tiers pour la discussion instantanée. Pour plus de détails concernant les cookies et autres données sensibles, veuillez lire notre <a href="https://agencegroom.com/politique-confidentialite/" class="cc-link">politique de confidentialité</a>.',
            },
            {
              title: 'Cookie strictement nécessaire',
              description:
                'Ce cookie est essentiel au bon fonctionnement de notre site. Sans lui, le site web ne fonctionnerait pas correctement.',
              toggle: {
                value: 'necessary',
                enabled: true,
                readonly: true,
              },
              cookie_table: [
                {
                  col1: 'cc_cookie',
                  col2: 'agencegroom.com',
                  col3: '365 jours',
                  col4: 'Mémorise les paramètres et les cookies acceptés par un visiteur.',
                  col5: 'Technique/Fonctionnel',
                },
              ],
            },
            {
              title: 'Splitbee',
              description:
                'Analyse les statistiques de consultation de notre site.',
              toggle: {
                value: 'analytics',
                enabled: false,
                readonly: false,
              },
              cookie_table: [
                {
                  col1: 'sb_uid',
                  col2: 'splitbee.io',
                  col3: '13 mois',
                  col4: "Ce cookie est défini par Splitbee Analytics et nous aide à comprendre comment les gens utilisent le site afin que nous puissions améliorer l'expérience.",
                  col5: 'Statistiques et analyses',
                },
              ],
            },
            {
              title: "Plus d'informations",
              description:
                "Pour toute question relative à notre politique en matière de cookies et à vos choix, n'hésitez pas à <a class='cc-link' href='mailto:concierge@agencegroom.com'>nous contacter</a>.",
            },
          ],
        },
      },
      en: {
        consent_modal: {
          title: 'Yum...cookies! 🍪',
          description:
            'We care about your data, and we would like to use cookies to improve your experience. We will not do anything without your consent. <button type="button" data-cc="c-settings" class="cc-link" aria-haspopup="dialog">Let me choose</button>',
          primary_btn: {
            text: 'Ok',
            role: 'accept_all', //'accept_selected' or 'accept_all'
          },
          secondary_btn: {
            text: 'No, thank you!',
            role: 'accept_necessary', //'settings' or 'accept_necessary'
          },
        },
        settings_modal: {
          title: 'Agence Groom - Cookie management',
          save_settings_btn: 'Save',
          accept_all_btn: 'Accept all',
          cookie_table_headers: [
            {col1: 'Name'},
            {col2: 'Domain'},
            {col3: 'Expiration'},
            {col4: 'Description'},
            {col5: 'Type'},
          ],
          blocks: [
            {
              title: 'Use of cookies',
              description:
                'We use cookies to improve your experience on ourwebsite, to measure our audience and also third party cookies for chat. For more details about cookies and other sensitive data, please read our <a href="https://agencegroom.com/en/privacy-policy/" class="cc-link">privacy policy</a>.',
            },
            {
              title: 'Cookie strictly necessary',
              description:
                'This cookie is essential to the proper functioning of our site. Without it, the website would not function properly.',
              toggle: {
                value: 'necessary',
                enabled: true,
                readonly: true,
              },
              cookie_table: [
                {
                  col1: 'cc_cookie',
                  col2: 'agencegroom.com',
                  col3: '365 days',
                  col4: 'Memorizes the settings and cookies accepted by a visitor.',
                  col5: 'Technical/Functional',
                },
              ],
            },
            {
              title: 'Splitbee',
              description: 'Analyzes the statistics of our website',
              toggle: {
                value: 'analytics',
                enabled: false,
                readonly: false,
              },
              cookie_table: [
                {
                  col1: 'sb_uid',
                  col2: 'splitbee.io',
                  col3: '13 months',
                  col4: 'This cookie is set by Splitbee Analytics and helps us understand how people are using the site so we can improve the experience.',
                  col5: 'Statistics and analysis',
                },
              ],
            },
            {
              title: 'More information',
              description:
                "If you have any questions about our cookie policy and your choices, please feel free to <a class='cc-link' href='mailto:concierge@agencegroom.com'>contact us</a>.",
            },
          ],
        },
      },
    },
  });
}

export default runCookieConsent;
