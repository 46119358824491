import runCookieConsent from './js/runCookieConsent';

const parentItems = document.querySelectorAll('.site-nav-dektop-item-title a');

function toggleAttributeExpand(nodeItem, event) {
  nodeItem.addEventListener(event, function () {
    let expandable = this.getAttribute('aria-haspopup');
    console.log(expandable);
    if (expandable) {
      let expanded = this.getAttribute('aria-expanded') === 'true' || false;
      this.setAttribute('aria-expanded', !expanded);
    }
  });
}
parentItems.forEach((parentItem) => {
  toggleAttributeExpand(parentItem, 'mouseenter');
  toggleAttributeExpand(parentItem, 'mouseleave');
});

// Cache DOM elements
const els = {
  navIcon: document.getElementById('js-nav-button'),
  nav: document.getElementById('js-nav'),
  overlay: document.getElementById('js-overlay'),
  burgerLine: document.querySelector('.burger__line'),
};

function toggleMenu() {
  els.burgerLine.classList.toggle('burger__line--active');
  document.body.classList.toggle('overflow');
  els.overlay.classList.toggle('site-overlay--visible');
  els.nav.classList.toggle('nav--visible');
}

[els.navIcon, els.overlay].forEach((el) => {
  el.addEventListener('click', toggleMenu);
});

runCookieConsent();
